import { useEffect, useState } from 'react';
import { InputAdornment, IconButton, Avatar, Button, TextField, FormControlLabel, Checkbox, Link, Paper, Box, Grid, Typography, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import frlogo from '../res/FRLogoFull.png';
import { useAuth } from '../utils/AuthProvider';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://firerover.com/">
                Fire Rover LLC.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export function SignIn() {
    const { signin } = useAuth();

    const [persist, setPersist] = useState(false);
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEmail(localStorage.getItem("email") ? localStorage.getItem("email") : "");
        setPersist(localStorage.getItem("remember") ? true: false);
    }, [])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const togglePersist = () => {
        setPersist(prev => !prev);
        if (!persist) {
            localStorage.setItem("remember", email);
        } else {
            localStorage.removeItem("email");
            localStorage.removeItem("remember");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        const pwd = data.get('password');
        data.set('password', '');
        if (persist) {
            localStorage.setItem("email", email);
        }
        signin(email, pwd, setLoading);
    };

    return (
        <Grid container component="main" sx={{ height: '92vh' }}>
            <Grid
                container
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    paddingTop: "8%"
                }}
                justifyContent="center"
            >
                {<img src={frlogo} alt="Fire Rover Logo" height={"600px"} />}
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={
                                {
                                    endAdornment: (
                                        < InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox onChange={togglePersist} checked={persist} value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {loading
                            ? <CircularProgress /> 
                            : "Sign In"}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/reset" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account? Register"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}