import axios from './Axios';
import { useAuth } from './AuthProvider';

function useRefreshToken() {
    const { cookies, refreshToken } = useAuth();

    const refresh = async () => {
        const userObj = {
            token: cookies.jwtToken,
            refreshToken: cookies.refToken
        };
        const response = await axios.post('api/User/refresh', userObj, {
            withCredentials: true
        });
        refreshToken(response.data.accessToken);
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;