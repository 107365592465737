// If you need to support IE11 uncomment the imports below
//import "react-app-polyfill/ie11";
//import "core-js/stable"; 
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import App from './App';
import { ThemeContextProvider } from './styles/ThemeContextProvider';
import { AuthProvider } from './utils/AuthProvider';

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);


root.render(
  <Router>
    <ThemeContextProvider>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </ThemeContextProvider>
  </Router>
);
