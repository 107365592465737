import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";

export function ProtectedRoutes() {
    const { cookies } = useAuth();
    const location = useLocation();

    return (
        cookies.jwtToken
            ? <Outlet />
            : <Navigate to="/signin" state={{ from: location }} replace />
    );
}