import { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../utils/AuthProvider";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";

const WelcomeName = () => {
    const { signout, cookies } = useAuth();

    const logout = () => {
        signout();
    }
    const [name, setName] = useState(null);

    useEffect(() => {
        if (cookies.name) {
            setName(cookies.name.split(' ')[0]);
        } else {
            setName(null);
        }
    }, [cookies]);

    if (name) {
        return (
            <Stack direction="row">
                <Typography variant="h6" sx={{ minWidth: '50%' }}>Welcome {name}!</Typography>
                <Button
                    variant="contained"
                    sx={{ ml: 2, height: "30px" }}
                    onClick={logout}>
                    Sign out
                </Button>
            </Stack>
        )
    } else {
        return (
            <Link href="/signin" variant="h6" color="inherit" underline="none">
                Sign in
            </Link>
        )
    }
};

export default WelcomeName;