import { useState, useEffect } from 'react';
import { Avatar, Button, Stack, TextField, InputAdornment, IconButton, Link, Grid, Box, 
        Typography, Container, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/Axios';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://firerover.com/">
                Fire Rover LLC.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const languages = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'Spanish', value: 'es' }
  ];

const USER_REGEX = /^[A-z][A-z_]{0,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-/=+><:;"']).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function Register() {
    const [firstName, setFirstName] = useState('');
    const [validFirstName, setFirstValidName] = useState(false);
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [validLastName, setLastValidName] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phone, setPhone] = useState(null);
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [preferredLanguage, setPreferredLanguage] = useState("en");

    const [code, setCode] = useState('');
    const [validCode, setValidCode] = useState(false);
    const [codeFocus, setCodeFocus] = useState(false);

    const [phoneCode, setPhoneCode] = useState('');
    const [validPhoneCode, setValidPhoneCode] = useState(false);
    const [phoneCodeFocus, setPhoneCodeFocus] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("Verification Code Sent!");
    const [dialogText, setDialogText] = useState("Please check your text messages for the verification code.");

    const navigate = useNavigate();

    useEffect(() => {
        setFirstValidName(USER_REGEX.test(firstName));
    }, [firstName]);

    useEffect(() => {
        setLastValidName(USER_REGEX.test(lastName));
    }, [lastName]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(String(email).toLowerCase()));
    }, [email]);

    useEffect(() => {
        setValidCode(code.length > 0);
    }, [code])

    useEffect(() => {
        setValidPhoneCode(phoneCode.length === 6);
    }, [phoneCode])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePhone = (newValue) => {
        setValidPhone(matchIsValidTel(newValue, {
            onlyCountryies: [], // optional,
            excludedCountryies: [], // optional
            continents: [] // optional
        }));
        setPhone(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const newUser = {
            email: data.get('email'),
            password: data.get('password'),
            name: data.get('firstName') + ' ' + data.get('lastName'),
            phone: data.get('phone'),
            registerCode: data.get('code'),
        };

        axios.post("/api/User/register", newUser)
            .then((response) => {
                handleSendPhoneVerificationCode();
            })
            .catch((error) => {
                console.error(error);
                alert("Error registering. Please try again.");
            });
    };

    const handleSendPhoneVerificationCode = () => {
        setOpenDialog(true);

        const newUser = {
            email: email,
            phone: phone,
            prefferedLanguage: preferredLanguage,
        };

        axios.post("/api/User/sendPhoneVerificationCode", newUser)
            .then((response) => {
                alert("Verification code sent successfully!")
            })
            .catch((error) => {
                console.error(error);
                alert("Error sending verification code. Please try again.");
            });
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/signin');
    };

    const handleConfirmDialog = (event) => {
        const newUser = {
            email: email,
            password: pwd,
            name: firstName + ' ' + lastName,
            phone: phone,
            phoneVerifyCode: phoneCode,
        };

        axios.post("/api/User/verifyPhoneCode", newUser)
            .then((response) => {
                handleCodeVerified(newUser);
            })
            .catch((error) => {
                console.error(error);
                handleCodeRejected();
            });
    }

    const handleCodeVerified = (event) => {
        setDialogTitle('Code Verified!');
        setDialogText('Registration complete! You will now be redirected to the sign in page.');
    }

    const handleCodeRejected = (event) => {
        setDialogTitle('Invalid Code! Please Try Again.')
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                error={!validFirstName && firstNameFocus}
                                helperText={!validFirstName && firstNameFocus ? "Only alphabetical characters allowed" : ""}
                                onFocus={() => setFirstNameFocus(true)}
                                onBlur={() => setFirstNameFocus(false)}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                error={!validLastName && lastNameFocus}
                                helperText={!validLastName && lastNameFocus ? "Only alphabetical characters allowed" : ""}
                                onFocus={() => setLastNameFocus(true)}
                                onBlur={() => setLastNameFocus(false)}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error={!validEmail && emailFocus}
                                helperText={!validEmail && emailFocus ? "Invalid email" : ""}
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                error={!validPwd && pwdFocus}
                                helperText={!validPwd && pwdFocus ? "Password must be atleast 8 characters long and have atleast 1 capital letter, 1 lowercase letter, 1 number, and 1 special character" : ""}
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                onChange={(e) => setPwd(e.target.value)}
                                InputProps={
                                    {
                                        endAdornment: (
                                            < InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                type="password"
                                id="confirmPassword"
                                label="Confirm Password"
                                name="confirmPassword"
                                autoComplete="confirmPassword"
                                error={!validMatch && matchFocus}
                                helperText={!validMatch && matchFocus ? "Passwords do not match" : ""}
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                                onChange={(e) => setMatchPwd(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiTelInput
                                fullWidth
                                label="Phone Number"
                                name="phone"
                                value={phone}
                                defaultCountry='US'
                                preferredCountries={['US', 'CA', 'FR']}
                                error={!validPhone && phoneFocus}
                                helperText={!validPhone && phoneFocus ? "Invalid phone number" : ""}
                                onFocus={() => setPhoneFocus(true)}
                                onBlur={() => setPhoneFocus(false)}
                                onChange={handlePhone} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Select Preferred Language</InputLabel>
                                <Select
                                    value={preferredLanguage}
                                    onChange={(e) => setPreferredLanguage(e.target.value)}
                                    label="Select Preferred Language"
                                    required={true}
                                >
                                    {languages.map((lang) => (
                                    <MenuItem key={lang.value} value={lang.value}>{lang.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="code"
                                label="Registration Code"
                                name="code"
                                autoComplete="code"
                                error={!validCode && codeFocus}
                                helperText={!validCode && codeFocus ? "Please enter the registration code" : ""}
                                onFocus={() => setCodeFocus(true)}
                                onBlur={() => setCodeFocus(false)}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!validPwd || !validMatch || !validFirstName || !validLastName || !validEmail || !validPhone || !validCode ? true : false}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/signin" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
            <Dialog open={openDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogText}
                </DialogContent>
                    {dialogTitle === "Verification Code Sent!" || dialogTitle === "Invalid Code! Please Try Again." ? (
                        <>
                            <Stack style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                <Typography>Your account has been created, but you will not recieve notifications until your phone number is verified.</Typography>
                            </Stack>
                            <Stack style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5%" }}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phoneCode"
                                    label="Phone Verification Code"
                                    name="phoneCode"
                                    error={!validPhoneCode && phoneCodeFocus}
                                    helperText={!validPhoneCode && phoneCodeFocus ? "Please enter the verification code sent to your mobile device" : ""}
                                    onFocus={() => setPhoneCodeFocus(true)}
                                    onBlur={() => setPhoneCodeFocus(false)}
                                    onChange={(e) => setPhoneCode(e.target.value)}
                                    />
                            </Stack>
                        </>
                    ) : (
                        null
                    )}
                <DialogActions>
                    {dialogTitle === "Verification Code Sent!" || dialogTitle === "Invalid Code! Please Try Again." ? (
                        <Button onClick={handleSendPhoneVerificationCode} color="primary">
                            Resend Code    
                        </Button>    
                    ) : (
                        null
                    )}
                    {dialogTitle === "Code Verified!" ? (
                        <Button onClick={handleCloseDialog} color="primary">
                            Close
                        </Button>
                    ) : (
                        <Button onClick={handleConfirmDialog} color="primary">
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Container>
    );
}