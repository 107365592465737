import { useState, useEffect } from 'react';
import { Avatar, Button, TextField, InputAdornment, IconButton, Link, Grid, Box, Typography, Container } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/Axios';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function ResetPassword() {

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [code, setCode] = useState('');
    const [validCode, setValidCode] = useState(false);

    const [codeSent, setCodeSent] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(String(email).toLowerCase()));
    }, [email]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSendCode = (event) => {
        event.preventDefault();

        console.log(email)
        axios.get("/api/User/sendCode/" + email)
            .then((response) => {
                setCodeSent(true);
            })
            .catch((error) => {
                console.error(error);
                alert("User does not exist. Please try again.");
            });
    };

    const handleCodeValidate = (event) => {
        event.preventDefault();
        const user = {
            email: email,
            resetCode: code,
            password: '',
        };

        axios.post("/api/User/validateCode", user)
            .then((response) => {
                alert("Code validated");
                setValidCode(true);
            })
            .catch((error) => {
                console.error(error);
                alert("Invalid code. Please try again.");
            });
    };

    const handleReset = (event) => {
        event.preventDefault();
        const user = {
            email: email,
            password: pwd,
            resetCode: code,
        };

        axios.post("/api/User/resetPassword", user)
            .then((response) => {
                alert("Password reset successfully!")
                navigate('/signin')
            })
            .catch((error) => {
                console.error(error);
                alert("Could not reset password. Please try again.");
                navigate('/reset')
            });
    }

    return (
        <Container component="main" maxWidth="lg">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockResetIcon />
                </Avatar>
                {codeSent ?
                    validCode ?
                        <>
                            <Typography component="h5" variant="h5">
                                Please enter the new password
                            </Typography>
                            <Box sx={{ mt: 3, maxWidth: "40%" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="password"
                                            label="Password"
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete="new-password"
                                            value={pwd}
                                            error={!validPwd && pwdFocus}
                                            helperText={!validPwd && pwdFocus ? <>
                                                Password must be atleast 8 characters long
                                                <br />
                                                Atlease 1 capital letter, 1 lowercase letter, 1 number, and 1 special character
                                              </>: ""}
                                            onFocus={() => setPwdFocus(true)}
                                            onBlur={() => setPwdFocus(false)}
                                            onChange={(e) => setPwd(e.target.value)}
                                            InputProps={
                                                {
                                                    endAdornment: (
                                                        < InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            autoComplete="confirmPassword"
                                            value={matchPwd}
                                            error={!validMatch && matchFocus}
                                            helperText={!validMatch && matchFocus ? "Passwords do not match" : ""}
                                            onFocus={() => setMatchFocus(true)}
                                            onBlur={() => setMatchFocus(false)}
                                            onChange={(e) => setMatchPwd(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    onClick={handleReset}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={!validPwd || !validMatch ? true : false}
                                >
                                    Reset Password
                                </Button>
                            </Box>
                        </>
                        :
                        <>
                            <Typography component="h5" variant="h5">
                                Please enter the code sent to your email
                            </Typography>
                            <Box sx={{ mt: 3, minWidth: "40%"  }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="code"
                                            label="Registration Code"
                                            name="code"
                                            autoComplete="code"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    onClick={handleCodeValidate}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Validate
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/reset" variant="body2">
                                            Resend the code?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    :
                    <>
                        <Typography component="h5" variant="h5">
                            Please enter your email address to recieve a reset code
                        </Typography>
                        <Box sx={{ mt: 3, minWidth: "40%"  }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={email}
                                        error={!validEmail && emailFocus}
                                        helperText={!validEmail && emailFocus ? "Invalid email" : ""}
                                        onFocus={() => setEmailFocus(true)}
                                        onBlur={() => setEmailFocus(false)}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                onClick={handleSendCode}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!validEmail ? true : false}
                            >
                                Send Code
                            </Button>
                        </Box>
                    </>
                }
            </Box>
        </Container>
    );
}