import React, { useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Typography from "@mui/material/Typography";
import { Grid, IconButton, Box, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, TextField, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useAxiosPrivate from "../utils/useAxiosPrivate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useAuth } from "../utils/AuthProvider";
import EditSettings from "../components/EditSettings";

function CheckCell(params) {
  return (
    <Box sx={{ justifyContent: "center" }}>
      {params.value ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} />}
    </Box>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  overflow: 'scroll',
  overflowX: "hidden",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function CollapsibleRow(props) {
  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { row } = props;

  const [rows, setRows] = useState([]);
  const [managerStatus, setManagerStatus] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [toDelete, setToDelete] = useState(null);
  const [promoteManager, setPromoteManager] = useState(null);
  const [demoteManager, setDemoteManager] = useState(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = () => {
    if (toDelete) {
      const userToDelete = {
        userId: toDelete.id,
        csId: props.row.csId
      }

      axiosPrivate.post("/api/Site/deleteUser", userToDelete)
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.error(error);
          alert("Error removing user. Please try again.");
        });
    }
    if (promoteManager) {
      const userToPromote = {
        userId: promoteManager.id,
        csId: props.row.csId
      }

      axiosPrivate.post("/api/Site/promoteUser", userToPromote)
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.error(error);
          alert("Error promoting user. Please try again.");
        });
    }
    if (demoteManager) {
      const userToDemote = {
        userId: demoteManager.id,
        csId: props.row.csId
      }

      axiosPrivate.post("/api/Site/demoteUser", userToDemote)
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.error(error);
          alert("Error demoting user. Please try again.");
        });
    }
    setToDelete(null);
    setPromoteManager(null);
    setDemoteManager(null);
    setOpenDialog(false);
  };

  const columns = [
    { field: 'fullName', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'cellPhone', headerName: 'Phone', width: 150 },
    { field: 'smsNotification', headerName: 'SMS Notification', width: 170, renderCell: CheckCell },
    { field: 'pushNotification', headerName: 'Push Notification', width: 150, renderCell: CheckCell },
    { field: 'emailNotification', headerName: 'Email Notification', width: 150, renderCell: CheckCell },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const handleDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDialogTitle("Remove user from Site?");
          setDialogText("Please confirm you are removing " + params.row.fullName + " from " + props.row.csName);
          setToDelete(params.row);
          setOpenDialog(true);
        };
        const handleManagerStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          if (managerStatus.find(u => u.userId === params.row.id && u.csId === props.row.csId).isManager) {
            setDialogTitle("Demote Site Manager to user?");
            setDialogText("Please confirm you are demoting " + params.row.fullName + " to a user for " + props.row.csName);
            setDemoteManager(params.row);
          }
          else {
            setDialogTitle("Promote user to Site Manager?");
            setDialogText("Please confirm you are promoting " + params.row.fullName + " to a manager for " + props.row.csName);
            setPromoteManager(params.row);
          }
          setOpenDialog(true);
        };

        return (
          <Stack direction="row">
            {isManager(props.row.csId) ? 
              <>
                <IconButton aria-label="promoteToManager" onClick={handleManagerStatus} color="primary">
                  {(managerStatus.find(u => u.userId === params.row.id && u.csId === props.row.csId).isManager) ? 
                    <PersonOffIcon />
                  :
                    <SupervisorAccountIcon />
                  }
                </IconButton>
                <IconButton aria-label="delete" onClick={handleDelete} color="primary">
                  <DeleteIcon />
                </IconButton>
                <EditSettings user={params.row.settings} />
              </>
            : 
              null
            }
          </Stack>
        );
      }
    },
  ];

  const loadData = (users) => {
    if (users && users.length > 0) {
      let userRows = [];
      users.forEach((user) => {
        if (user['email'] !== cookies.user) {
          userRows.push(
            {
              id: user['userId'],
              fullName: user['fullName'],
              email: user['email'],
              cellPhone: user['countryCode'] + ' ' + user['cellPhone'],
              smsNotification: user['sms'],
              pushNotification: user['pushNotification'],
              emailNotification: user['emailNotification'],
              settings: user
            }
          )
        }
      });

      setRows(userRows);
    }
  };

  const fetchData = () => {
    const controller = new AbortController();

    const getSites = async () => {
      try {
        const response = await axiosPrivate.get('/api/Site/siteUsers/' + row.csId, {
          signal: controller.signal
        });

        loadData(response.data);

      } catch (err) {
        alert(err);
      }
    }

    const getManagerStatus = async () => {
      try {
        const response = await axiosPrivate.get('/api/Site/siteUserStatus/', {
          signal: controller.signal
        });
        
        setManagerStatus(response.data);

      } catch (err) {
        alert(err);
      }
    }

    getSites();
    getManagerStatus();

    return () => {
      controller.abort();
    }
  }

  const isManager = (csId) => {
    try {
      return managerStatus ? managerStatus.find(u => u.userName === cookies.user && u.csId === csId).isManager : false;
    } catch {
      return false
    }
  }


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, []);

  return (
    <>
      <Paper sx={{ height: 400, minWidth: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{ border: 0 }}
        />
      </Paper>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={handleConfirmDialog} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function Sites() {

  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [managerStatus, setManagerStatus] = useState([]);

  const [inviteCs, setInviteCs] = useState(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const handleOpen = (csId) => {
    setInviteCs(csId);
    setOpen(true);
  };
  const handleClose = () => {
    setInviteCs(null);
    setInviteEmail("");
    setOpen(false);
  };

  const handleSendInvite = () => {

    const customerContact = {
      csId: inviteCs,
      csContactEmail: inviteEmail
    }

    axiosPrivate.post("/api/User/sendEmailInvite", customerContact)
      .then(() => {
        alert("Invite sent");
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        alert("Error sending invite. Please try again.");
      })
  };

  const fetchData = () => {
    const controller = new AbortController();

    const getSites = async () => {
      try {
        const response = await axiosPrivate.get('/api/Site/sites/' + cookies.user, {
          signal: controller.signal
        });

        setRows(response.data);

      } catch (err) {
        alert(err);
      }
    }

    const getManagerStatus = async () => {
      try {
        const response = await axiosPrivate.get('/api/Site/siteUserStatus', {
          signal: controller.signal
        });
        
        setManagerStatus(response.data);

      } catch (err) {
        alert(err);
      }
    }

    getSites();
    getManagerStatus();

    return () => {
      controller.abort();
    }
  }

  const isManager = (csId) => {
    console.log(managerStatus);
    try {
      return managerStatus.find(u => u.userName === cookies.user && u.csId === csId).isManager;
    } catch {
      return false
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(String(inviteEmail).toLowerCase()));
  }, [inviteEmail]);

  const columns =
    [
      {
        accessorKey: 'csName',
        header: 'Site Name',
        enableColumnOrdering: false,
        minSize: 200,
      },
      {
        accessorKey: 'csAddress1',
        header: 'Address',
        minSize: 250,
      },
      {
        accessorKey: 'csAddress2',
        header: 'Suite/Apt',
        minSize: 100,
        size: 150
      },
      {
        accessorKey: 'csState',
        header: 'State',
        minSize: 30,
        maxSize: 60,
      },
      {
        accessorKey: 'csAccountManager',
        header: 'Account Manager',
        enableColumnOrdering: false,
        minSize: 200,
      },
      {
        accessorKey: 'centralStation',
        header: 'Central Station',
        size: 130,
      },
      {
        accessorKey: 'csId',
        header: '',
        size: 100,
        Cell: ({ row }) => (
          <Button
            variant="contained"
            disabled={!isManager(row.original.csId)}
            onClick={() => { handleOpen(row.original.csId) }}
            sx={{ padding: "3px" }}>
            Invite user
          </Button>
        ),
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableColumnResizing: true,
    paginationDisplayMode: 'pages',
    layoutMode: 'grid',
    initialState: {
      columnPinning: { left: ['mrt-row-expand', 'csName'] },
      pagination: { pageSize: 100, pageIndex: 0 },
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      <CollapsibleRow key={row.original.id} row={row.original} />
  });

  return (
    <>
      <Grid
        container
        justifyContent="center" // Center vertically
        alignItems="center" // Center horizontally
        style={{ height: "2%", paddingTop: "1%", marginBottom: "1%" }} // Center vertically
      >
        <Typography
          variant="h6"
          alignItems="center"
          fontFamily="monospace">
          Sites Management
        </Typography>
      </Grid>
      <MaterialReactTable table={table} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Invite User
          </Typography>

          <TextField
            label="Email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            error={!validEmail && emailFocus && inviteEmail.length > 0}
            helperText={!validEmail && emailFocus && inviteEmail.length > 0 ? "Invalid email" : ""}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            sx={{ marginBottom: 2 }}
          />

          <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSendInvite}>
                Send
              </Button>
            </Box>
          </Grid>

        </Box>
      </Modal>
    </>
  );
}