import axios from "axios";
const BASE_URL = 'https://sparkmobilebackenddev.azurewebsites.net/';
// const BASE_URL = 'https://localhost:5035/';
// const BASE_URL = 'https://localhost:7230/';

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});