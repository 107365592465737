import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import WelcomeName from "./WelcomeName";
import DarkModeToggle from "./DarkModeToggle";
import { Link as RouterLink } from "react-router-dom";
import frdarklogo from "../../res/FRLogo_Black.png";
import { Box, IconButton, Typography } from "@mui/material";
import BreadcrumbTrail from "./BreadcrumbTrail";
import { useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import NavMenu from "./NavMenu";
import NotificationsButton from "../Notifications";
import { useAuth } from "../../utils/AuthProvider";

const NavBar = () => {
  const { cookies } = useAuth();
  const navigate = useNavigate();

  const defaultStyle = {
    height: "80px"
  };

  return (
    <AppBar
      position="sticky"
      color="secondary"
      sx={{ top: 0, zIndex: 100 }}
      style={defaultStyle}
    >
      <Toolbar
        sx={{ justifyContent: "space-between" }}
        style={{ paddingBottom: "0px" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NavMenu />

          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            variant="h6"
            sx={{ paddingTop: "3%" }}
          >
            {<img src={frdarklogo} alt="Fire Rover Logo" height={"60px"} />}
          </Link>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", paddingLeft: "30px" }}
          >
            <BreadcrumbTrail />
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {
            <>
              <WelcomeName />
            </>
          }
          <DarkModeToggle />
          {cookies.user ? 
            <NotificationsButton/>
          : 
            null
          }
          <IconButton onClick={() => navigate(`/settings`)} >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;