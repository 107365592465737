import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { Notifications } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";

export default function NavMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRedirect = (link) => {
    navigate(`/${link}`);
    setOpen(false);
  }



  return (
    <div>
      <Button style={{ color: 'white' }} onClick={toggleDrawer(true)}><MenuIcon /></Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {['Notifications'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => handleRedirect('notifications')}>
                  <ListItemIcon>
                    <Notifications/>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
            {['Notification Settings'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => handleRedirect('settings')}>
                  <ListItemIcon>
                    <EditNotificationsIcon/>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
            {['Site Management'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => handleRedirect('sites')}>
                  <ListItemIcon>
                    <ManageAccountsIcon/>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
            {['Account'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => handleRedirect('account')}>
                  <ListItemIcon>
                    <AccountCircleIcon/>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button onClick={() => handleRedirect('settings')} variant='contained' style={{ marginTop: '40px' }}>Settings</Button>
        </div> */}
      </Drawer>
    </div>
  );
}