import { axiosPrivate } from "./Axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { useAuth } from "./AuthProvider";

function useAxiosPrivate() {
    const refresh = useRefreshToken();
    const { cookies, signout } = useAuth();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${cookies.jwtToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                try {
                    if (error?.response?.status === 401 && !prevRequest?.sent) {
                        prevRequest.sent = true;
                        const newAccessToken = await refresh();
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosPrivate(prevRequest);
                    }
                }
                catch (err) {
                    console.error("Session Timeout");
                    signout();
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
        // eslint-disable-next-line
    }, [cookies, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;