import React, { useState, useEffect } from "react";
import {
    Box, Typography, Switch, Button, IconButton,
    FormControlLabel, FormGroup, Select, MenuItem, InputLabel, Modal,
    Container, Paper, Grid, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, Snackbar, CircularProgress, Checkbox, Stack
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';

import { Info as InfoIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import useAxiosPrivate from "../utils/useAxiosPrivate";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    minWidth: '40%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const widthStyle = { width: "fit-content" };

export default function EditSettings({ user, onUpdateUser, onClose }) {
    const [editedUser, setEditedUser] = useState(user);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [notificationId, setNotificationId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [pressureOn, setPressureOn] = useState(false);
    const [sprayOn, setSprayOn] = useState(false);
    const [hotWorksAlerts, setHotWorks] = useState(false);
    const [waterOn, setWaterOn] = useState(false);
    const [eButtonOn, setEButtonOn] = useState(false);
    const [eCareOn, setECareOn] = useState(false);
    const [weekdaysOn, setWeekdaysOn] = useState(false);
    const [weekdayScheduleOn, setWeekdayScheduleOn] = useState(false);
    const [weekendsOn, setWeekendsOn] = useState(false);
    const [weekendScheduleOn, setWeekendScheduleOn] = useState(false);
    const [weekdaysStartTime, setWeekDaysStartTime] = useState(null);
    const [weekdaysEndTime, setWeekDaysEndTime] = useState(null);
    const [weekendsStartTime, setWeekEndsStartTime] = useState(null);
    const [weekendsEndTime, setWeekEndsEndTime] = useState(null);
    const [weekdayNotificationMode, setWeekdayNotificationMode] = useState("accumulation");
    const [weekendNotificationMode, setWeekendNotificationMode] = useState("accumulation");
    const [name, setName] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [email, setEmail] = useState("");
    const [preferredLanguage, setPreferredLanguage] = useState("1");
    const [modalVisible, setModalVisible] = useState(false);
    const [smsOn, setSMSOn] = useState(false);
    const [pushNotificationsOn, setPushNotificationsOn] = useState(false);
    const [emailsOn, setEmailsOn] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const handleSave = () => {
        //setLoading(true);
        setEditedUser(editedUser)

        const notifSettings = {
            notificationId: notificationId,
            userId: userId,
            pressureOn: pressureOn,
            sprayOn: sprayOn,
            hotWorksAlerts: hotWorksAlerts,
            waterOn: waterOn,
            eButton: eButtonOn,
            eCare: eCareOn,
            sms: smsOn,
            pushNotification: pushNotificationsOn,
            emailNotification: emailsOn,
            weekdayNotificationMode: weekdayNotificationMode,
            weekendNotificationMode: weekendNotificationMode,
            fullName: name,
            cellPhone: cellPhone.substring(cellPhone.indexOf(' ') + 1),
            email: email,
            countryCode: cellPhone.substring(0, cellPhone.indexOf(' ')),
            prefferedLanguage: preferredLanguage,
            weekdays: weekdaysOn,
            weekEnds: weekendsOn,
            weekdaySchedule: weekdayScheduleOn,
            weekEndSchedule: weekendScheduleOn,
            weekdayStartTime: weekdaysStartTime && dayjs(weekdaysStartTime).isValid() ? new Date(weekdaysStartTime).toLocaleTimeString('en-US', { hour12: false }) : null,
            weekdayEndTime: weekdaysEndTime && dayjs(weekdaysEndTime).isValid() ? new Date(weekdaysEndTime).toLocaleTimeString('en-US', { hour12: false }) : null,
            weekendStartTime: weekendsStartTime && dayjs(weekendsStartTime).isValid() ? new Date(weekendsStartTime).toLocaleTimeString('en-US', { hour12: false }) : null,
            weekendEndTime: weekendsEndTime && dayjs(weekendsEndTime).isValid() ? new Date(weekendsEndTime).toLocaleTimeString('en-US', { hour12: false }) : null
          };
      
          axiosPrivate.post("/api/NotificationSettings/saveNotificationSettings", notifSettings)
            .then(() => {
              handleClose();
              setSnackbarOpen(true);
            })
            .catch((error) => {
              console.error(error);
              alert("Error saving settings. Please try again.");
            })
            .finally(() => {
              setLoading(false);
            })
    };

    const loadData = (settings) => {
        setNotificationId(settings.notificationId);
        setUserId(settings.userId);
        setPressureOn(settings.pressureOn);
        setSprayOn(settings.sprayOn);
        setHotWorks(settings.hotWorksAlerts);
        setWaterOn(settings.waterOn);
        setEButtonOn(settings.eButtonOn);
        setECareOn(settings.eCare);
        setSMSOn(settings.sms);
        setPushNotificationsOn(settings.pushNotification);
        setEmailsOn(settings.emailNotification);
        setWeekdayNotificationMode(settings.weekdayNotificationMode ?? "accumulation");
        setWeekendNotificationMode(settings.weekendNotificationMode ?? "accumulation");
        setName(settings.fullName);
        setCellPhone(settings.countryCode + " " + settings.cellPhone);
        setEmail(settings.email);
        setPreferredLanguage(settings.prefferedLanguage);
        setWeekdaysOn(settings.weekdays);
        setWeekendsOn(settings.weekends);
        setWeekdayScheduleOn(settings.weekdaySchedule);
        setWeekendScheduleOn(settings.weekendSchedule);
        setWeekDaysStartTime(dayjs(`2023-01-01T${settings.weekdayStartTime}`));
        setWeekDaysEndTime(dayjs(`2023-01-01T${settings.weekdayEndTime}`));
        setWeekEndsStartTime(dayjs(`2023-01-01T${settings.weekendStartTime}`));
        setWeekEndsEndTime(dayjs(`2023-01-01T${settings.weekendEndTime}`));
    }

    useEffect(() => {
        loadData(user);
        // eslint-disable-next-line 
    }, []);

    const handleSms = (sms) => {
        if (sms && !smsOn) {
            alert('SMS Enabled: Message & Data rates may apply.');
        }
        setSMSOn(!smsOn);
    }

    const handlePreAction = () => {
        setPressureOn(!pressureOn);
        setWaterOn(!waterOn);
    };

    const handleWeekdays = (event) => {
        if (!event.target.checked) {
            setWeekdayScheduleOn(false);
        }

        setWeekdaysOn(!weekdaysOn);
    }

    const handleWeekends = (event) => {
        if (!event.target.checked) {
            setWeekendScheduleOn(false);
        }

        setWeekendsOn(!weekendsOn);
    }

    const notificationModes = [
        { label: 'Accumulation', value: 'accumulation' },
        { label: 'Start/Stop', value: 'startStop' }
    ];

    return (
        <>
            <IconButton aria-label="edit" onClick={handleOpen} color="primary">
                <EditIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button variant="outlined" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

                    <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
                        Edit User Settings
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Container>
                            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                                <Typography variant="h6" gutterBottom>Alerts</Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={pressureOn && waterOn} onChange={handlePreAction} />}
                                        label="System Pre-Action"
                                        style={widthStyle}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={sprayOn} onChange={() => setSprayOn(!sprayOn)} />}
                                        label="Suppression Activated"
                                        style={widthStyle}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={hotWorksAlerts} onChange={() => setHotWorks(!hotWorksAlerts)} />}
                                        label="Mobile Unit Modes"
                                        style={widthStyle}
                                    />
                                    {/* <FormControlLabel
                control={<Switch checked={eButtonOn} onChange={() => setEButtonOn(!eButtonOn)} />}
                label="E-Button"
                style={widthStyle}
              />
              <FormControlLabel
                control={<Switch checked={eCareOn} onChange={() => setECareOn(!eCareOn)} />}
                label="E-Care"
                style={widthStyle}
              /> */}
                                </FormGroup>
                            </Paper>

                            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                                <Typography variant="h6" gutterBottom>Notification Types</Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={smsOn} onChange={handleSms} />}
                                        label="SMS"
                                        style={widthStyle}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={pushNotificationsOn} onChange={() => setPushNotificationsOn(!pushNotificationsOn)} />}
                                        label="Push Notifications"
                                        style={widthStyle}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={emailsOn} onChange={() => setEmailsOn(!emailsOn)} />}
                                        label="Email Notifications"
                                        style={widthStyle}
                                    />
                                </FormGroup>
                            </Paper>

                            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Time of Day Controls
                                </Typography>

                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Switch checked={weekdaysOn} onChange={handleWeekdays} />}
                                            label="Weekdays"
                                        />
                                    </Grid>
                                    {weekdaysOn && (
                                        <>
                                            <Grid item >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={weekdayScheduleOn}
                                                            onChange={() => setWeekdayScheduleOn(!weekdayScheduleOn)}
                                                        />
                                                    }
                                                    label="Weekday Schedule"
                                                />
                                            </Grid>
                                            {weekdayScheduleOn && (
                                                <>
                                                    <Grid item container spacing={2} alignItems="center" sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
                                                        <Grid item>
                                                            <Typography>Start Time:</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <TimePicker label="Start Time" value={weekdaysStartTime} onChange={setWeekDaysStartTime} />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item container spacing={2} alignItems="center">
                                                        <Grid item>
                                                            <Typography>End Time:</Typography>
                                                        </Grid>
                                                        <Grid item style={{ paddingLeft: "21px", paddingBottom: "10px" }}>
                                                            <TimePicker label="End Time" value={weekdaysEndTime} onChange={setWeekDaysEndTime} />
                                                        </Grid>
                                                    </Grid>

                                                    <Stack direction="row" sx={{ mt: 2, paddingLeft: "15px" }}>
                                                        <Typography variant="h6" gutterBottom>Notification Mode</Typography>
                                                        <IconButton onClick={() => setModalVisible(true)} sx={{ padding: "0px 4px 0px 4px", marginBottom: "8px", marginLeft: "4px" }}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Stack>
                                                    <FormControl fullWidth style={{ paddingLeft: "15px", paddingBottom: "30px" }}>
                                                        <InputLabel>Select Notification Mode</InputLabel>
                                                        <Select
                                                            value={weekdayNotificationMode}
                                                            onChange={(e) => setWeekdayNotificationMode(e.target.value)}
                                                            label="Select Notification Mode"
                                                            disabled={!weekdaysOn && !weekendsOn}
                                                        >
                                                            {notificationModes.map((mode) => (
                                                                <MenuItem key={mode.value} value={mode.value}>{mode.label}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    <Dialog open={modalVisible} onClose={() => setModalVisible(false)}>
                                                        <DialogTitle>Notification Modes Info</DialogTitle>
                                                        <DialogContent>
                                                            <Typography>
                                                                Available when schedule is enabled. <br /><br />
                                                                <strong>Accumulation</strong>: Notifications received during off hours will be held and delivered in bulk at the start of the next operational period. <br /><br />
                                                                <strong>Start/Stop</strong>: Notifications received during off hours will not be delivered but will be stored in archive.
                                                            </Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => setModalVisible(false)}>Close</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </>
                                            )}
                                        </>
                                    )}

                                    <Grid item>
                                        <FormControlLabel
                                            control={<Switch checked={weekendsOn} onChange={handleWeekends} />}
                                            label="Weekends"
                                        />
                                    </Grid>
                                    {weekendsOn && (
                                        <>
                                            <Grid item >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={weekendScheduleOn}
                                                            onChange={() => setWeekendScheduleOn(!weekendScheduleOn)}
                                                        />
                                                    }
                                                    label="Weekend Schedule"
                                                />
                                            </Grid>
                                            {weekendScheduleOn && (
                                                <>
                                                    <Grid item container spacing={2} alignItems="center" sx={{ paddingTop: "10px", paddingBottom: "5px" }}>
                                                        <Grid item>
                                                            <Typography>Start Time:</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <TimePicker label="Start Time" value={weekendsStartTime} onChange={setWeekEndsStartTime} />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item container spacing={2} alignItems="center">
                                                        <Grid item>
                                                            <Typography>End Time:</Typography>
                                                        </Grid>
                                                        <Grid item style={{ paddingLeft: "21px", paddingBottom: "10px" }}>
                                                            <TimePicker label="End Time" value={weekendsEndTime} onChange={setWeekEndsEndTime} />
                                                        </Grid>
                                                    </Grid>

                                                    <Stack direction="row" sx={{ mt: 2, paddingLeft: "15px" }}>
                                                        <Typography variant="h6" gutterBottom>Notification Mode</Typography>
                                                        <IconButton onClick={() => setModalVisible(true)} sx={{ padding: "0px 4px 0px 4px", marginBottom: "8px", marginLeft: "4px" }}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Stack>
                                                    <FormControl fullWidth style={{ paddingLeft: "15px", paddingBottom: "30px" }}>
                                                        <InputLabel>Select Notification Mode</InputLabel>
                                                        <Select
                                                            value={weekendNotificationMode}
                                                            onChange={(e) => setWeekendNotificationMode(e.target.value)}
                                                            label="Select Notification Mode"
                                                            disabled={!weekdaysOn && !weekendsOn}
                                                        >
                                                            {notificationModes.map((mode) => (
                                                                <MenuItem key={mode.value} value={mode.value}>{mode.label}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    <Dialog open={modalVisible} onClose={() => setModalVisible(false)}>
                                                        <DialogTitle>Notification Modes Info</DialogTitle>
                                                        <DialogContent>
                                                            <Typography>
                                                                Available when Time of Day Controls is turned on. <br /><br />
                                                                <strong>Accumulation</strong>: Notifications received during off hours will be held and delivered in bulk at the start of the next operational period. <br /><br />
                                                                <strong>Start/Stop</strong>: Notifications received during off hours will not be delivered but will be stored in archive.
                                                            </Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => setModalVisible(false)}>Close</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Paper>

                            <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button variant="contained" onClick={handleSave}>
                                        {loading
                                            ? <CircularProgress />
                                            : "Save"}
                                    </Button>
                                </Box>
                            </Grid>
                        </Container>
                    </LocalizationProvider>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    User notification settings updated!
                </MuiAlert>
            </Snackbar>
        </>
    );
}
