import React, { useEffect, useState } from 'react';
import {
  Box, Typography, TextField, Button, IconButton, InputAdornment,
  Container, Paper, Grid, CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useAxiosPrivate from "../utils/useAxiosPrivate";
import { useAuth } from "../utils/AuthProvider";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import axios from '../utils/Axios';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

export function Account() {

  const [notificationId, setNotificationId] = useState(null);
  const [name, setName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [oldPwd, setOldPwd] = useState('');
  const [validOldPwd, setValidOldPwd] = useState(false);

  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const fetchData = () => {
    const controller = new AbortController();

    const getSettings = async () => {
      try {
        const response = await axiosPrivate.get('/api/User/settings/' + cookies.user, {
          signal: controller.signal
        });

        loadData(response.data);

      } catch (err) {
        console.log(err);
      }
    }

    getSettings();

    return () => {
      controller.abort();
    }
  }

  const loadData = (settings) => {
    setNotificationId(settings.notificationId);
    setName(settings.fullName);
    setCellPhone(settings.countryCode + settings.cellPhone);
    setEmail(settings.email);
  }

  const handleSave = () => {

    setLoading(true);

    const notifSettings = {
      notificationId: notificationId,
      fullName: name,
      cellPhone: cellPhone.substring(cellPhone.indexOf(' ') + 1),
      email: email.toLowerCase(),
      countryCode: cellPhone.substring(0, cellPhone.indexOf(' '))
    };

    axiosPrivate.post("/api/NotificationSettings/savePersonalInfo", notifSettings)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving settings. Please try again.");
      })
      .finally(() => {
        console.warn(notifSettings);
        setLoading(false);
      })
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, [])

  const handlePhone = (newValue) => {
    if (matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: []
    })) {
    }
    setCellPhone(newValue);
  };

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOldPwdValidate = (event) => {
      event.preventDefault();
      const user = {
          email: cookies.user,
          password: oldPwd,
      };

      axios.post("/api/User/validatePassword", user)
          .then((response) => {
              setValidOldPwd(true);
          })
          .catch((error) => {
              console.error(error);
              alert("Password Incorrect. Please try again.");
          });
  };

  const handleChange = (event) => {
      event.preventDefault();
      const user = {
          email: cookies.user,
          password: pwd,
      };

      axios.post("/api/User/changePassword", user)
          .then((response) => {
              alert("Password reset successfully!")
              handleCleanup();
          })
          .catch((error) => {
              console.error(error);
              alert("Could not change password. Please try again.");
          });
  }

  const handleCleanup = () => {
    setValidOldPwd(false);
    setOldPwd('');
    setPwd('');
    setMatchPwd('');

  }

  return (
    <>
      <Container style={{ paddingTop: "40px" }}>
        <Grid
          container
          justifyContent="center" // Center vertically
          alignItems="center" // Center horizontally
          style={{ height: "2%", paddingTop: "1%", marginBottom: "1%" }} // Center vertically
          >
          <Typography
            variant="h6"
            alignItems="center"
            fontFamily="monospace">
            Account
          </Typography>
        </Grid>
        <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Personal Information</Typography>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            />
          <MuiTelInput
            fullWidth
            label="Cell Phone"
            name="phone"
            value={cellPhone}
            defaultCountry='US'
            preferredCountries={['US', 'CA', 'FR']}
            onChange={handlePhone}
            sx={{ marginBottom: 2 }}
            />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            />
          <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSave}>
                {loading
                  ? <CircularProgress />
                  : "Save"}
              </Button>
            </Box>
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Change Password</Typography>
          {validOldPwd ?
            <>
              <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <TextField
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    error={!validPwd && pwdFocus}
                    helperText={!validPwd && pwdFocus ? 
                      "Password must be atleast 8 characters long and have atleast 1 capital letter, 1 lowercase letter, 1 number, and 1 special character" 
                      : ""}
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    onChange={(e) => setPwd(e.target.value)}
                    InputProps={
                        {
                            endAdornment: (
                                < InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    }
                />
              </Grid>
              <Grid style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <TextField
                      required
                      fullWidth
                      type="password"
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      autoComplete="confirmPassword"
                      error={!validMatch && matchFocus}
                      helperText={!validMatch && matchFocus ? "Passwords do not match" : ""}
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                      onChange={(e) => setMatchPwd(e.target.value)}
                  />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" onClick={handleChange}>
                    {loading
                      ? <CircularProgress />
                      : "Change Password"}
                  </Button>
                </Box>
              </Grid>
            </>
            :
            <>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Current Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  onChange={(e) => setOldPwd(e.target.value)}
                  InputProps={
                      {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                  }
              />

              <Grid item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" onClick={handleOldPwdValidate}>
                    {loading
                      ? <CircularProgress />
                      : "Validate Password"}
                  </Button>
                </Box>
              </Grid>
            </>
          }
        </Paper>
      </Container>
    </>
  )
}