import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { useThemeContext } from "./styles/ThemeContextProvider";
import { SignIn } from "./pages/Signin";
import { Register } from "./pages/Register";
import { ProtectedRoutes } from "./components/ProtectRoutes";
import NotFound from "./pages/404";
import './App.css'
import NotificationSettings from "./components/NotificationSettings";
import { ResetPassword } from "./pages/ResetPassword";
import { Sites } from "./pages/Sites";
import { Account } from "./pages/Account";
import { Notifications } from "./pages/NotificationHub";

function App() {

  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageLayout>
        <Pages />
      </PageLayout>
    </ThemeProvider>
  );
}

function Pages() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/signin" element={<SignIn />} />
      <Route path="/register" element={<Register />} />
      <Route path='/reset' element={<ResetPassword />} />

      {/* we want to protect these routes */}
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="settings" element={<NotificationSettings />} />
        <Route path="sites" element={<Sites />} />
        <Route path="account" element={<Account />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>

      {/* catch all */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;