import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

export function Home() {

  return (
    <>
      <Grid
        container
        justifyContent="center" // Center vertically
        alignItems="center" // Center horizontally
        style={{ height: "10%", paddingTop: "10%", marginBottom: "5%" }} // Center vertically
      >
        <Typography
          color="red"
          variant="h3"
          alignItems="center"
          fontFamily="monospace">
          Fire Rover&nbsp;
        </Typography>
        <Typography
          variant="h3"
          alignItems="center"
          fontFamily="monospace">
          Web Portal
        </Typography>
      </Grid>
    </>
  );
}