import React, { useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Typography from "@mui/material/Typography";
import { Grid, Box, Paper } from "@mui/material";
import useAxiosPrivate from "../utils/useAxiosPrivate";
import { useAuth } from "../utils/AuthProvider";
import moment from 'moment';

const style = {
  width: '60%',
  overflow: 'scroll',
  overflowX: "hidden",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CollapsibleRow(props) {

  const servicePhone = "248-200-3730"

  const convertTime = (timeString) => {
    const time = moment(timeString, 'HH:mm:ss');
    return time.format('h:mm A');
  };

  return (
    <>
      <Paper sx={style}>
        <Box>
          <Typography>Unit: {props.row ? props.row.productName : null}</Typography>
          <Typography>Site: {props.row ? props.row.siteName : null}</Typography>
          <Typography>Time: {props.row ? props.row.dateOfEvent : null}, {convertTime(props.row ? props.row.timeOnly : null)}</Typography>
        </Box>
        <Box>
          <img
            src={props.row ? props.row.fileLink : null}
            alt={''}
            width={"100%"}
          />
        </Box>
        <Box>
          <Typography>Account Manger: {props.row ? props.row.accountManager : null}</Typography>
          <Typography>Account Manager Phone: {props.row ? props.row.accountManagerPhone : null}</Typography>
          <Typography>Central Station Phone: {props.row ? props.row.centralStationPhone : null}</Typography>
          <Typography>Service Department Phone: {servicePhone}</Typography>
        </Box>
      </Paper>
    </>
  );
}

export function Notifications() {

  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [rows, setRows] = useState([]);

  const convertTime = (timeString) => {
    const time = moment(timeString, 'HH:mm:ss');
    return time.format('h:mm A');
  };

  const loadData = (notifications) => {
    if (notifications && notifications.length > 0) {
      let userRows = [];
      notifications.forEach((notification) => {
        userRows.push(
          {
            id: notification["eventNotificationId"],
            message: "FireRover Activated",
            eventType: notification["eventType"],
            productName: notification["productName"],
            siteName: notification["siteName"],
            dateOfEvent: notification["dateOfEvent"],
            timeOnly: convertTime(notification["timeOnly"]),
            fileLink: notification["fileLink"],
            accountManager: notification["accountManager"],
            accountManagerPhone: notification["accountManagerPhone"],
            centralStationPhone: notification["centralStationPhone"]
          }
        )
      });

      setRows(userRows);
    }
  };

  const fetchData = () => {
    const controller = new AbortController();

    const getEventNotifications = async () => {
      try {
        if (cookies.user) {
          const response = await axiosPrivate.get('/api/User/eventNotifications/' + cookies.user, {
            signal: controller.signal
          })
  
          loadData(response.data.slice().reverse());
        }
      } catch (err) {
        console.log(err);
      }
    }

    getEventNotifications();

    return () => {
      controller.abort();
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, []);

  const columns =
    [
      {
        accessorKey: 'eventType',
        header: 'Event',
        enableColumnOrdering: false,
        size: 30,
      },
      {
        accessorKey: 'productName',
        header: 'Unit',
        size: 30,
      },
      {
        accessorKey: 'siteName',
        header: 'Site',
        maxSize: 100,
      },
      {
        accessorKey: 'dateOfEvent',
        header: 'Date',
        size: 40,
      },
      {
        accessorKey: 'timeOnly',
        header: 'Time',
        size: 100,
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableColumnResizing: true,
    paginationDisplayMode: 'pages',
    layoutMode: 'grid',
    initialState: {
      columnPinning: { left: ['mrt-row-expand', 'eventType'] },
      pagination: { pageSize: 100, pageIndex: 0 },
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    //custom expand button rotation
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    //conditionally render detail panel
    renderDetailPanel: ({ row }) =>
      <CollapsibleRow key={row.original.id} row={row.original} />
  });

  return (
    <>
      <Grid
        container
        justifyContent="center" // Center vertically
        alignItems="center" // Center horizontally
        style={{ height: "2%", paddingTop: "1%", marginBottom: "1%" }} // Center vertically
      >
        <Typography
          variant="h6"
          alignItems="center"
          fontFamily="monospace">
          Notifications
        </Typography>
      </Grid>
      <MaterialReactTable table={table} />
    </>
  );
}